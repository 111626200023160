
<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_information.warehouseServiceList') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="listReload">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="loadData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                     <template v-slot:cell(index)="data">
                     {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(division_name)="data">
                      {{ data.item.division_name }}
                    </template>
                     <template v-slot:cell(distict_name)="data">
                      {{ data.item.distict_name }}
                    </template>
                     <template v-slot:cell(upzila_name)="data">
                      {{ data.item.upzila_name }}
                    </template>
                    <template v-slot:cell(warehouse_name)="data">
                      {{ data.item.warehouse_name }}
                    </template>
                    <template v-slot:cell(remarks)="data">
                      {{ data.item.remarks }}
                    </template>
                    <template v-slot:cell(status)="data">
                      {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                    </template>
                     <template v-slot:cell(attachment)="data">
                        <a :href="warehouseServiceBaseUrl + 'download-attachment?file=uploads/warehouse-servicesoriginal/' + data.item.attachment" class="btn btn-success"><i class="ri-download-cloud-line" ></i></a>
                      </template>
                  </b-table>
                  <b-pagination
                    v-if="pagination.totalRows"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchClick"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { wareFarmerServiceList, userStatus } from '../../../api/routes'

export default {
  components: {
  },
  data () {
    return {
      warehouseServiceBaseUrl: warehouseServiceBaseUrl,
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.ExternalUserIrrigation.static.perPage,
         slOffset: 1
      },
      testId: 0,
      warehouseList: [],
      upazilaList: [],
      districtList: []
    }
  },
  computed: {
    loading () {
      return this.$store.state.ExternalUserIrrigation.static.loading
    },
    listReload () {
      return this.$store.state.ExternalUserIrrigation.static.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('warehouse_information.division'), class: 'text-left' },
          { label: this.$t('warehouse_config.region'), class: 'text-left' },
          { label: this.$t('warehouse_information.district'), class: 'text-left' },
          { label: this.$t('warehouse_information.upazilla'), class: 'text-left' },
          { label: this.$t('warehouse_information.warehouse_name'), class: 'text-left' },
          { label: this.$t('warehouse_information.remarks_Bn'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('warehouseFarmer.file'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'division_name_bn' },
          { key: 'region_name_bn' },
          { key: 'distict_name_bn' },
          { key: 'upzila_name_bn' },
          { key: 'warehouse_name_bn' },
          { key: 'remarks_bn' },
          { key: 'status' },
          { key: 'attachment' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'division_name' },
          { key: 'region_name' },
          { key: 'distict_name' },
          { key: 'upzila_name' },
          { key: 'warehouse_name' },
          { key: 'remarks' },
          { key: 'status' },
          { key: 'attachment' }
        ]
      }
      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.checkApprovedUser()
  },
  methods: {
    downloadAttachment (item) {
      var fileURL = warehouseServiceBaseUrl + 'storage/uploads/warehouse-servicesoriginal/' + item.attachment
      window.open(fileURL)
    },
    searchClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length,
        division_id: '',
        district_id: '',
        upazilla_id: '',
        warehouse_id: 0,
        remarks: ''
      }
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
       this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    async loadData () {
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: true })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      const result = await RestApi.getData(warehouseServiceBaseUrl, wareFarmerServiceList, params)
      if (result.success) {
        this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: false })
        this.paginationData(result.data)
        return this.ListData(result.data.data)
      } else {
        this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: false })
      }
    },
    ListData (data) {
      let tmpData = {}
      let tmpData2 = {}
      let tmpUpzila = {}
      const commonObj = this.$store.state.ExternalUserIrrigation.commonObj
      const listData = data.map(item => {
        tmpData = commonObj.divisionList.find(orgItem => orgItem.value === item.division_id)
        tmpData2 = commonObj.districtList.find(orgItem => orgItem.value === item.district_id)
        tmpUpzila = commonObj.upazilaList.find(orgItem => orgItem.value === item.upazilla_id)
        const orgData = { division_name: tmpData.text, division_name_bn: tmpData.text_bn, distict_name: tmpData2.text, distict_name_bn: tmpData2.text_bn, upzila_name: tmpUpzila.text, upzila_name_bn: tmpUpzila.text_bn }
        return Object.assign({}, item, orgData)
      })
      return listData
    },
    getRegionDistrictList (regionId = null) {
      RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
            if (response) {
                const dataObject = response.filter(document => document.region_id === regionId)
                this.regionDistrictLists = dataObject.map(obj => {
                        if (this.$i18n.locale === 'bn') {
                            return { value: obj.district_id, text: this.districtListBn[obj.district_id] }
                        } else {
                            return { value: obj.district_id, text: this.districtList[obj.district_id] }
                        }
                })
            }
        })
    },
    async checkApprovedUser () {
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const authUser = this.$store.state.Auth.authUser.username
        const result = await RestApi.getData(authServiceBaseUrl, userStatus + authUser)
        const status = result.status
        if (status === 1) {
            this.loadData()
        } else {
            this.$router.push({ name: 'external_user.warehouse_farmer' + '.dashboard' })
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
